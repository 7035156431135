<template>
  <b-overlay
    :show="showLoading"
    rounded="sm"
    variant="white"
    opacity="0.50"
  >
    <b-card
      :title="$t('pages.add_new_property_page.name_description')"
      class="position-static"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col
              md="4"
            >
              <b-form-group
                :label="$t('pages.add_new_property_page.name')"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    v-model="addPropertyObject.form.property.main.name"
                    type="text"
                    placeholder="AT-9-SD-3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="12"
            >
              <b-form-group
                :label="$t('pages.add_new_property_page.descAr')"
                label-for="descAr"
              >
                <validation-provider
                  #default="{ errors }"
                  name="descAr"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="addPropertyObject.form.property.local.description"
                    placeholder="أدخل وصف مختصر للوحدة"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="3"
            >
              <b-form-group
                :label="$t('pages.add_new_property_page.bedrooms_no')"
                label-for="bedrooms_no"
              >
                <validation-provider
                  #default="{ errors }"
                  name="bedrooms_no"
                  rules="required"
                >
                  <b-form-input
                    v-model="addPropertyObject.form.property.main.bedrooms"
                    type="number"
                    placeholder="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="3"
            >
              <b-form-group
                :label="$t('pages.add_new_property_page.bathrooms_no')"
                label-for="bathrooms_no"
              >
                <validation-provider
                  #default="{ errors }"
                  name="bathrooms_no"
                  rules="required"
                >
                  <b-form-input
                    v-model="addPropertyObject.form.property.main.bathrooms"
                    type="number"
                    placeholder="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="3"
            >
              <b-form-group
                :label="$t('pages.add_new_property_page.spaceArea')"
                label-for="spaceArea"
              >
                <validation-provider
                  #default="{ errors }"
                  name="spaceArea"
                  rules="required"
                >
                  <b-form-input
                    v-model="addPropertyObject.form.property.main.space_area"
                    type="number"
                    placeholder="120"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

    </b-card>

    <b-card
      :title="$t('pages.add_new_property_page.rent_status')"
      class="position-static"
    >
      <b-row>
        <b-col
          md="2"
        >
          <b-form-checkbox
            v-model="addPropertyObject.form.property.main.rent_data.rent"
            value="true"
          >
            {{ $t('pages.add_new_property_page.rent') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row v-if="addPropertyObject.form.property.main.rent_data.rent">
        <b-col
          md="4"
        >
          <b-form-radio-group
            v-model="addPropertyObject.form.property.main.rent_data.rent_type"
            :options="rent_type_list"
            class="demo-inline-spacing mb-1"
            value-field="id"
            text-field="name"
            disabled-field="disabled"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-card
      :title="$t('pages.add_new_property_page.type_status')"
      class="position-static"
    >
      <div>
        <b-form-radio-group
          v-model="addPropertyObject.form.property.main.type_id"
          :options="typesList"
          class="demo-inline-spacing mb-1"
          value-field="type_id"
          text-field="name"
          disabled-field="disabled"
        />
      </div>

      <div>
        <b-form-radio-group
          v-model="addPropertyObject.form.property.main.status_id"
          :options="statusesList"
          class="demo-inline-spacing mb-1"
          value-field="property_status_id"
          text-field="name"
          disabled-field="disabled"
        />
      </div>

    </b-card>

    <b-card
      :title="$t('pages.add_new_property_page.price')"
      class="position-static"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col
              md="4"
            >
              <b-form-group
                :label="$t('pages.add_new_property_page.total_price')"
                label-for="total_price"
              >
                <validation-provider
                  #default="{ errors }"
                  name="total_price"
                  rules="required"
                >
                  <b-form-input
                    v-model="addPropertyObject.form.property.main.price"
                    type="number"
                    placeholder="1300000"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="2"
            >
              <b-form-checkbox
                v-model="addPropertyObject.form.property.main.bankable"
                value="true"
              >
                {{ $t('pages.add_new_property_page.bankable') }}
              </b-form-checkbox>
            </b-col>
            <b-col
              md="2"
            >
              <b-form-checkbox
                v-model="addPropertyObject.form.property.main.initiative_bankable"
                value="true"
              >
                {{ $t('pages.add_new_property_page.initiative_bankable') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

    </b-card>

    <b-card
      :title="$t('pages.add_new_property_page.address')"
      class="position-static"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col
              md="3"
            >
              <b-form-group
                label="المدينة"
                label-for="city"
              >
                <validation-provider
                  #default="{ errors }"
                  name="city"
                  rules="required"
                >
                  <v-select
                    ref="contractName"
                    v-model="addPropertyObject.form.address.main.city_id"
                    :options="citiesList"
                    label="name"
                    :state="errors.length > 0 ? false:null"
                    :reduce="name => name.id"
                    placeholder="إختر المدينة"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

    </b-card>

    <b-card
      :title="$t('pages.add_new_property_page.gallery')"
      class="position-static"
    >
      <b-row>
        <b-col md="12">
          <div
            id="preview"
            class="text-center"
          >
            <image-uploader
              :preview="true"
              :class-name="['fileinput', { 'fileinput--loaded': hasImage }]"
              capture="environment"
              :debug="2"
              :scale-ratio="1"
              :quality="1"
              :max-size="1.5"
              accept=".jpg, .png, .jpeg"
              do-not-resize="['gif', 'svg']"
              :auto-rotate="true"
              output-format="blob"
              @input="setImage"
              @onUpload="startImageResize"
              @onComplete="endImageResize"
            >
              <label
                slot="upload-label"
                for="fileInput"
              >
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                  >
                    <path
                      class="path1"
                      d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                    />
                  </svg>
                </figure>
                <span class="upload-caption">{{
                  hasImage ? "Replace" : "Click to upload"
                }}</span>
              </label>
            </image-uploader>
          </div>
        </b-col>
      </b-row>
      <b-table
        responsive="sm"
        :items="addPropertyObject.form.galleryList"
        :fields="galleryTableFields"
      >
        <template #cell(featureImage)="data">
          <b-form-radio
            v-model="data.item.featureImage"
            name="some-radios"
            :value="true"
          />
        </template>

        <template #cell(url)="data">
          <b-avatar
            size="70px"
            :src="data.item.url"
          />
        </template>

        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            @click="deleteGalleryImage(data.item)"
          >
            حذف
          </b-button>
        </template>
      </b-table>

    </b-card>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="createProperty"
    >
      إضافة
    </b-button>
  </b-overlay>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BCard, BCol, BForm, BFormGroup, BRow, BFormInput, BFormTextarea,
  BFormRadioGroup, BFormCheckbox, BTable, BFormRadio, BAvatar, BButton, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ImageUploader from 'vue-image-upload-resize'
import vSelect from 'vue-select'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'AddNewProperty',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BFormGroup,
    BFormInput,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    BFormRadioGroup,
    BFormCheckbox,
    BTable,
    BFormRadio,
    BAvatar,
    BButton,
    vSelect,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      hasImage: false,
      showLoading: false,
      imageGalleryData: {
        url: null,
        imageFile: null,
      },
      galleryTableItems: [
        {
          age: 40, first_name: 'Dickerson', last_name: 'Macdonald', Occupation: 'Job',
        },
        {
          age: 21, first_name: 'Larsen', last_name: 'Shaw', Occupation: 'Job',
        },
      ],
      rent_type_list: [
        { id: 1, name: 'يومي' },
        { id: 2, name: 'شهري' },
      ],
      galleryTableFields: [
        { key: 'url', label: 'الصورة' },
        { key: 'imageFile', label: 'الإسم' },
        { key: 'actions' },
      ],
      addPropertyObject: {
        form: {
          address: {
            main: {
              city_id: 1,
            },
            local: {
              building_no: 1,
              street: 'djhdsahdkashd',
            },
          },
          property: {
            main: {
              name: null,
              type_id: 1,
              status_id: 1,
              bedrooms: 1,
              bathrooms: 1,
              space_area: null,
              price: null,
              bankable: false,
              initiative_bankable: false,
              rent_data: {
                rent: false,
                rent_type: null,
              },
            },
            local: {
              description: null,
            },
          },
          galleryList: [],
        },
        formData: new FormData(),
        url: 'admin/properties',
        storeName: 'properties',
        hasFormData: true,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      typesList: 'types/typesList',
      statusesList: 'statuses/statusesList',
      citiesList: 'cities/citiesList',
    }),
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight)
    await this.loadAllTypes()
    await this.loadAllStatuses()
    await this.loadAllCities()
  },
  methods: {
    ...mapActions({
      get: 'get',
      add: 'add',
    }),

    /**
     * Create New Property Function.
     *
     * @return void
     */
    async createProperty() {
      this.addPropertyObject.formData = new FormData()
      const vm = this
      Object.entries(this.addPropertyObject.form).forEach(([key, value]) => {
        if (key === 'galleryList') {
          // eslint-disable-next-line array-callback-return
          this.addPropertyObject.form.galleryList.map((value2, key2) => {
            vm.addPropertyObject.formData.append(`galleryList[${key2}][imageFile]`, value2.imageFile)
            vm.addPropertyObject.formData.append(`galleryList[${key2}][featureImage]`, value2.featureImage)
          })
        } else if (key === 'property') {
          vm.addPropertyObject.formData.append('property[main][name]', value.main.name)
          vm.addPropertyObject.formData.append('property[main][type_id]', value.main.type_id)
          vm.addPropertyObject.formData.append('property[main][status_id]', value.main.status_id)
          vm.addPropertyObject.formData.append('property[main][bedrooms]', value.main.bedrooms)
          vm.addPropertyObject.formData.append('property[main][bathrooms]', value.main.bathrooms)
          vm.addPropertyObject.formData.append('property[main][space_area]', value.main.space_area)
          vm.addPropertyObject.formData.append('property[main][price]', value.main.price)
          vm.addPropertyObject.formData.append('property[main][bankable]', value.main.bankable)
          vm.addPropertyObject.formData.append('property[main][initiative_bankable]', value.main.initiative_bankable)
          if (value.main.rent_data.rent) {
            vm.addPropertyObject.formData.append('property[rent_data][rent]', value.main.rent_data.rent)
            vm.addPropertyObject.formData.append('property[rent_data][rent_type]', value.main.rent_data.rent_type)
          }
          vm.addPropertyObject.formData.append('property[local][description]', value.local.description)
        } else if (key === 'address') {
          vm.addPropertyObject.formData.append('address[main][city_id]', value.main.city_id)
          // vm.addPropertyObject.formData.append('address[local][building_no]', value.local.building_no)
          // vm.addPropertyObject.formData.append('address[local][street]', value.local.street)
        } else {
          this.addPropertyObject.formData.append(key, value)
        }
      })

      this.showLoading = true
      await this.add(this.addPropertyObject).then(response => {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'success',
            text: response.data.message,
          },
        })
        this.$router.replace({
          name: 'all_properties',
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error,
          },
        })
      }).finally(() => {
        this.showLoading = false
      })
    },
    setImage(output) {
      this.hasImage = true
      this.imageGalleryData.imageFile = output
      this.imageGalleryData.url = URL.createObjectURL(output)
    },
    startImageResize() {
      // this.$vs.loading({ text: 'Compressing Image Please Wait ...' })
    },
    endImageResize() {
      this.addGalleryImage()
      /* this.$vs.loading.close()
      this.successNotify('All is done :)') */
    },

    /**
     * Add Gallery Image.
     *
     * @return void
     */
    addGalleryImage() {
      this.addPropertyObject.form.galleryList.push({
        url: this.imageGalleryData.url,
        imageFile: this.imageGalleryData.imageFile,
        featureImage: false,
      })
      this.imageGalleryData.url = null
      this.imageGalleryData.imageFile = null
      this.hasImage = false
    },

    /**
     * Fetch All Types.
     *
     * @return void
     */
    async loadAllTypes() {
      await this.get({
        storeName: 'types',
        url: '/admin/types',
        commitUrl: 'types/SET_TYPES',
      })
    },

    /**
     * Fetch All Cities.
     *
     * @return void
     */
    async loadAllCities() {
      await this.get({
        storeName: 'cities',
        url: '/admin/cities',
        commitUrl: 'cities/SET_CITIES',
      })
    },

    /**
     * Fetch All Statuses.
     *
     * @return void
     */
    async loadAllStatuses() {
      await this.get({
        storeName: 'statuses',
        url: '/admin/statuses',
        commitUrl: 'statuses/SET_STATUSES',
      })
    },

    /**
     * Delete Gallery Image
     *
     * @return void
     */
    deleteGalleryImage(item) {
      this.addPropertyObject.form.galleryList.splice(this.addPropertyObject.form.galleryList.indexOf(item), 1)
    },
  },
}
</script>

<style>
  #fileInput {
    visibility: hidden;
  }
</style>
